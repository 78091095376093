<template>
  <nav class="left menu">
    <router-link
      v-for="(item, i) in navItems"
      :key="i"
      :to="item.to"
      class="item"
      :title="item.title"
    >
      <span v-text="item.title" />
      <small class="coming-soon" v-if="item.soon" v-text="`breve`" />
    </router-link>
  </nav>
</template>

<script>
import { enableComingSoon } from '@/config'
import { mapActions, mapGetters } from 'vuex'
import { currentYear, currentMonth } from '@/helpers'

export default {
  name: 'THeaderNav',
  data () {
    return {
      defaultNavItems: [
        { to: { name: 'dashboard' }, title: this.$t('Home'), soon: enableComingSoon },
        { to: { name: 'transactions' }, title: this.$tc('Transaction', 2) },
        { to: { name: 'donations' }, title: this.$tc('Donation', 2) },
        { to: { name: 'donors' }, title: this.$tc('Donor', 2) },
        { to: { name: 'transferences' }, title: this.$tc('Transference', 2) },
        {
          to: {
              name: 'files',
              params: {
                year: currentYear(),
                month: currentMonth(),
                type: 'debit' }
              },
          title: 'Débito'
        },
        { to: { name: 'files.bankslip' }, title: 'Boleto', soon: enableComingSoon },
      ],
    }
  },
  computed: {
    ...mapGetters({
      navItems: 'getNavItems',
      isDirectMail: 'isDirectMail',
      hasDebitAccount: 'hasDebitAccount'
    })
  },
  methods: {
    ...mapActions(['setNavItems']),
  },

  mounted() {
    let newNavItems = this.defaultNavItems
    if (this.isDirectMail) {
      newNavItems = []
      this.defaultNavItems.forEach(navItem => {
        if (!navItem.soon) {
          if (navItem.to.name === 'files' && !this.hasDebitAccount) return
          newNavItems.push(navItem)
        }
      })
    }
    this.setNavItems(newNavItems)
  }
}
</script>

<style lang="scss">
.left.menu {
  small.coming-soon {
    display: inline-block;
    position: absolute;
    top: 14px;
    right: -5px;
    color: #fff;
    font-weight: 800;
    font-size: 10px;
    line-height: 16px;
    background-color: #d1d1d1;
    border-radius: 3px;
    padding: 0 3px;
  }
}
</style>
