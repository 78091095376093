var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "left menu" },
    _vm._l(_vm.navItems, function(item, i) {
      return _c(
        "router-link",
        {
          key: i,
          staticClass: "item",
          attrs: { to: item.to, title: item.title }
        },
        [
          _c("span", { domProps: { textContent: _vm._s(item.title) } }),
          item.soon
            ? _c("small", {
                staticClass: "coming-soon",
                domProps: { textContent: _vm._s("breve") }
              })
            : _vm._e()
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }